@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;700&display=swap);
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  color: #251f57;
}

#root {
  width: 100%;
  height: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
}

.textContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 50px;
}

.titleHome {
  font-family: "Montserrat";
  font-size: 55px;
  margin-top: 0;
  margin-bottom: 0;
}

.textHome {
  font-family: "Montserrat";
  font-size: 16px;
  margin-bottom: 0;

  line-height: 25px;
}
.commissions {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: bold;
}

.button-contact {
  border: none;
  height: 40px;
  width: 180px;
  border-radius: 20px;
  background-color: #5e00d6;
  color: white;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
  outline-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-top: 30px;
}
.containerPhoto {
  padding-left: 20px;
}

.cvPhoto {
  height: 450px;
  width: 450px;
}

.HomePhoto {
  width: 500px;
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
  .containerPhoto {
    padding-left: 0;
    margin-top: 4rem;
  }
  .textContainer {
    margin-top: 1rem;
  }
  .titleHome {
    font-size: 48px;
  }
  .HomePhoto {
    max-width: 100%;
  }
  .button-contact {
    width: auto;
  }
}

.header {
  height: 112px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 50px;
  margin-left: 50px;
  position: relative;
  padding: 16px 0px;
  align-items: center;
}
.logo {
  height: 66px;
  margin-top: 10px;
  text-decoration: none;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav.mobile{
  display: none;
}

.title {
  padding-right: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  font-family: "Montserrat";
}

.button {
  border: none;
  height: 30px;
  width: 150px;
  border-radius: 20px;
  background-color: #5e00d6;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 900px) {
  .header {
    margin: 0 3rem;
  }

  .nav {
    display: none;
  }
  .nav.mobile{
    display: block;
  }
}

.layout {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.layout.legal-page .layout-content {
  height: 100%;
}

.layout-header {
  background-color: white;
  border-bottom-width: 1px;
  height: 64px;
}

.layout-main {
  flex: 1 1;
  min-height: 0;
  border-left-width: 1px;
  border-right-width: 1px;
  margin: 0 auto;
  max-width: 1050px;
  display: flex;
  align-items: center;
  padding-bottom: 5rem;
  overflow: auto;
  max-height: 100%;
}

.layout-title {
  align-items: center;
  border-bottom-width: 1px;
  display: flex;
  height: 52px;
  padding: 0 15px;
}

.layout-footer {
  align-items: center;
  border-top-width: 1px;
  display: flex;
  height: 112px;
  font-size: 12px;
}

.layout-header,
.layout-footer {
  justify-content: center;
  font-weight: 600;
  padding: 0 15px;
}

.layout-footer .legal {
  margin-left: 0.3rem;
}

@media (max-width: 900px) {
  .layout {
    height: auto;
  }
}

