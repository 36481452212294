.layout {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.layout.legal-page .layout-content {
  height: 100%;
}

.layout-header {
  background-color: white;
  border-bottom-width: 1px;
  height: 64px;
}

.layout-main {
  flex: 1;
  min-height: 0;
  border-left-width: 1px;
  border-right-width: 1px;
  margin: 0 auto;
  max-width: 1050px;
  display: flex;
  align-items: center;
  padding-bottom: 5rem;
  overflow: auto;
  max-height: 100%;
}

.layout-title {
  align-items: center;
  border-bottom-width: 1px;
  display: flex;
  height: 52px;
  padding: 0 15px;
}

.layout-footer {
  align-items: center;
  border-top-width: 1px;
  display: flex;
  height: 112px;
  font-size: 12px;
}

.layout-header,
.layout-footer {
  justify-content: center;
  font-weight: 600;
  padding: 0 15px;
}

.layout-footer .legal {
  margin-left: 0.3rem;
}

@media (max-width: 900px) {
  .layout {
    height: auto;
  }
}
