@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;700&display=swap");

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
}

.textContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 50px;
}

.titleHome {
  font-family: "Montserrat";
  font-size: 55px;
  margin-top: 0;
  margin-bottom: 0;
}

.textHome {
  font-family: "Montserrat";
  font-size: 16px;
  margin-bottom: 0;

  line-height: 25px;
}
.commissions {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: bold;
}

.button-contact {
  border: none;
  height: 40px;
  width: 180px;
  border-radius: 20px;
  background-color: #5e00d6;
  color: white;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
  outline-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-top: 30px;
}
.containerPhoto {
  padding-left: 20px;
}

.cvPhoto {
  height: 450px;
  width: 450px;
}

.HomePhoto {
  width: 500px;
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
  .containerPhoto {
    padding-left: 0;
    margin-top: 4rem;
  }
  .textContainer {
    margin-top: 1rem;
  }
  .titleHome {
    font-size: 48px;
  }
  .HomePhoto {
    max-width: 100%;
  }
  .button-contact {
    width: auto;
  }
}
