@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;700&display=swap");

.header {
  height: 112px;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-right: 50px;
  margin-left: 50px;
  position: relative;
  padding: 16px 0px;
  -webkit-box-align: center;
  align-items: center;
}
.logo {
  height: 66px;
  margin-top: 10px;
  text-decoration: none;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav.mobile{
  display: none;
}

.title {
  padding-right: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  font-family: "Montserrat";
}

.button {
  border: none;
  height: 30px;
  width: 150px;
  border-radius: 20px;
  background-color: #5e00d6;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 900px) {
  .header {
    margin: 0 3rem;
  }

  .nav {
    display: none;
  }
  .nav.mobile{
    display: block;
  }
}
